import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { SocialMediaLinks } from '../SocialMediaLinks';

const AboutSuperfuds = ({ getI18nText }) => (
  <div className="footer-about-superfuds">
    <Typography variant="subtitle1" className="subtitle pb-1 title">
      {getI18nText('footer.about.title')}
    </Typography>
    <Typography variant="body2" className="contentText text-margin link">
      <a href="/quienes-somos" rel="noopener nofollow noreferrer">
        {getI18nText('footer.about.link.option_2')}
      </a>
    </Typography>
    <SocialMediaLinks getI18nText={getI18nText} />
  </div>
);

AboutSuperfuds.defaultProps = {
  getI18nText: () => { },
};

AboutSuperfuds.propTypes = {
  getI18nText: PropTypes.func,
};

export { AboutSuperfuds };
