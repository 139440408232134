import React from 'react';
import { withRouter } from 'react-router-dom';
import { Container, Grid, Typography, Button } from '@material-ui/core';
import { history } from '@redux/store';

import './styles.scss';

import { Layout } from '../Layout/layout';

const WhoWeAre = () => (
  <Layout>
    <Container id="superfuds-page" className="superfuds-page">
      <Grid
        container
        className="pt-6"
        direction="row"
        alignItems="flex-start"
        justifyContent="center"
        spacing={3}
      >
        <Grid item xs={10}>
          <Typography variant="h5" className="section-title">
            ¿Quiénes Somos?
          </Typography>
          <Typography className="pt-3 paragraph">
            En Superfüds somos una empresa con propósito. Nos vemos como el aliado de los
            emprendedores que ofrece todos sus recursos en fulfillment, distribución y financiación
            con la finalidad de democratizar el estilo de vida saludable.
          </Typography>
          <Typography className="pt-3 paragraph">
            Innovamos en procesos y capital humano, en tecnología y las necesidades que la industria
            exige para ser competitivos en un mercado que sigue en crecimiento.
          </Typography>
          <Typography className="pt-3 paragraph">
            Somos el aliado, socio y compañero que facilita el crecimiento de marcas emergentes que
            buscan mejorar el mundo a través de un propósito consciente.
          </Typography>
        </Grid>

        {/*     <Grid item xs={12} md={6}>
          <img
            src="/placeholder.svg?height=300&width=400"
            className="image-superfuds"
            alt="Superfüds"
          />
        </Grid> */}

        <Grid item xs={10}>
          <Typography variant="h5" className="section-title">
            Nuestra Visión
          </Typography>
          <Typography className="pt-3 paragraph">
            Nos imaginamos un mundo mejor, un mundo responsable, donde cooperamos y crecemos entre
            todos. Somos una comunidad que se apoya entre sí mientras contribuimos a un propósito en
            común: <strong>Hacer el mundo más saludable</strong>.
          </Typography>
          <Typography className="pt-3 paragraph">
            Tenemos como objetivo, hacerlo todo por facilitar y acompañar en el camino del nuevo
            emprendedor para llegar a nuevos comercios mientras elevamos el nivel de las marcas.
          </Typography>
        </Grid>

        <Grid item xs={10}>
          <Typography variant="h5" className="section-title">
            Manifiesto
          </Typography>
          <Typography className="pt-3 paragraph">
            Superfüds, es un espacio para despegar y volar alto, para creer en el potencial propio y
            crear de manera colectiva. Somos la experiencia ganada en cada tropiezo, somos el
            conocimiento y la fuerza con la que nos hemos levantado para llegar cada vez más lejos.
          </Typography>
          <Typography className="pt-3 paragraph" style={{ fontWeight: 'bold' }}>
            Somos los que trabajamos concientemente con el propósito de hacer del mundo un lugar
            colaborativo, novedoso y más amable para todos.
          </Typography>
        </Grid>

        <Grid item xs={10}>
          <Button
            className="mt-6 link"
            color="primary"
            variant="contained"
            onClick={() => history.push('/')}
          >
            <Typography>Regresar al home</Typography>
          </Button>
        </Grid>
      </Grid>
    </Container>
  </Layout>
);

WhoWeAre.propTypes = {};

WhoWeAre.defaultProps = {};

export const WhoWeAreContainer = withRouter(WhoWeAre);

// export default Error500;
