import React, { Suspense, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { ThemeProvider, StylesProvider } from '@material-ui/core';
import { SfApp } from '@superfuds/sf-app';
import { HelmetProvider } from 'react-helmet-async';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { Loader } from '@superfuds/saori';
import mixpanel from 'mixpanel-browser';
import ReactGA from 'react-ga4';
import { v4 as uuidv4 } from 'uuid';

import { ProtectedRoute } from '@components/atoms';
import { Error500 } from '@containers/Error500Container/Error500Container';
import { WhoWeAreContainer } from '@containers/WhoWeAreContainer/WhoWeAreContainer';
import { TermsandConditionsContainer as terms } from '@containers/TermsandConditionsContainer';

import { Creators as StartupActions } from '@reducers/Startup';

import { reduxPersistConfig } from '@config/reduxPersistConfig';
import { history } from '@redux/store';
import { NODE_ENVIRONMENT, DEVICESESSIONID } from '@constants/index';
import ProductsByBrand from '@containers/ProductsByBrandContainer/by-brand.container';
import ProductsByHint from '@containers/ProductsByHintContainer/by-hint.container';
import ProductsByCategory from '@containers/ProductsByCategoryContainer/by-category.container';
import ProductsByDiets from '@containers/ProductsByDietsContainer/by-diets.container';
import Cart from '@containers/CartContainer/CartContainer';
import Checkout from '@containers/CheckoutContainer/Checkout.container';
import Payment from '@containers/StatusPayment/StatusPayment';
import ProductDetail from '@containers/ProductDetailContainer/detail.container';

import { useKeepUiVersionInQueryStringIfPresent } from '@hooks/use-keep-ui-version';
import { useKeepClientIdInQueryStringIfPresent } from '@hooks/use-keep-client-id';
import { requestForToken, onMessageListener } from './firebase';
import { theme } from './theme';

import '@superfuds/saori/dist/index.css';
import './index.scss';

const Error404 = loadable(() => import('@containers/Error404Container/Error404Container'));
/* const ProductDetail = loadable(() => import('@containers/ProductDetailContainer/detail.container'));
 const Cart = loadable(() => import('@containers/CartContainer/CartContainer')); */
const MyOrders = loadable(() => import('@containers/MyOrdersContainer/my-orders.container'));
const Provider = loadable(() => import('@containers/ProviderContainer/ProviderContainer'));
const HomeContainer = loadable(() => import('@containers/HomeContainer/home.container'));
const Brands = loadable(() => import('@containers/BrandsContainer/brands.container'));
/* const Checkout = loadable(() => import('@containers/CheckoutContainer/Checkout.container')); */
const Register = loadable(() => import('@containers/RegisterContainer/RegisterContainer'));
/* const Payment = loadable(() => import('@containers/StatusPayment/StatusPayment'));

const ProductsByHint = loadable(() =>
  import('@containers/ProductsByHintContainer/by-hint.container'),
);

const ProductsByCategory = loadable(() =>
  import('@containers/ProductsByCategoryContainer/by-category.container'),
);

const ProductsByDiets = loadable(() =>
  import('@containers/ProductsByDietsContainer/by-diets.container'),
);

 const ProductsByBrand = loadable(() =>
  import('@containers/ProductsByBrandContainer/by-brand.container'),
); */

const AppBase = ({ user }) => {
  useKeepUiVersionInQueryStringIfPresent();
  useKeepClientIdInQueryStringIfPresent();
  const dispatch = useDispatch();

  useEffect(() => {
    requestForToken();
    if (!reduxPersistConfig.active) {
      dispatch(StartupActions.startup());
    }

    if (localStorage.getItem(DEVICESESSIONID) === null) {
      localStorage.setItem(DEVICESESSIONID, uuidv4());
    }
    mixpanel.init('20a64c135dfd48703151891eda0f75f5', { debug: false });
    ReactGA.initialize('G-137C6K21P0');
    history.listen((location) => {
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    });
  }, [dispatch]);

  onMessageListener()
    .then((payload) => {
      // console.log(payload);
      /*   setNotification({title: payload?.notification?.title, body: payload?.notification?.body});      */
    })
    .catch((err) => console.log('failed: ', err));

  return (
    <SfApp
      loadingComponent=""
      environment={NODE_ENVIRONMENT}
      user={user}
      history={history}
      enableEvents={false}
      enableDebug={false}
    >
      <Suspense fallback={<Loader />}>
        <ThemeProvider theme={theme}>
          <StylesProvider injectFirst>
            <HelmetProvider>
              <Router history={history}>
                {/* <UseScrollToTop useLocation={useLocation} /> */}
                <Switch>
                  <Route path="/" exact component={HomeContainer} />
                  <Route path="/500" component={Error500} />
                  <Route path="/404" component={Error404} />
                  <Route path="/quienes-somos" component={WhoWeAreContainer} />
                  <Route path="/proveedor" component={Provider} />
                  <ProtectedRoute path="/checkout" exact component={Checkout} />
                  <ProtectedRoute path="/register" exact component={Register} />
                  <ProtectedRoute path="/cart" exact component={Cart} />
                  <ProtectedRoute path="/pedidos" exact component={MyOrders} />
                  <ProtectedRoute path="/confirmacion-pago" exact component={Payment} />
                  <Route path="/politicas-de-privacidad" exact />
                  <Route path="/terminos-y-condiciones" exact component={terms} />
                  <Route path="/categoria/:categoryId" exact component={ProductsByCategory} />
                  <Route path="/marca/:brandId" component={ProductsByBrand} />
                  <Route path="/todas-brands" exact component={Brands} />
                  <Route path="/buscar/:hint" component={ProductsByHint} />
                  <Route path="/habitos-saludables/:dietsId" exact component={ProductsByDiets} />
                  <Route path="/" exact />
                  <Route
                    path="/categoria/:categoryId/:subCategoryId"
                    exact
                    component={ProductsByCategory}
                  />
                  <Route path="/p/:productId" exact component={ProductDetail} />
                  <Redirect from="*" exact to="/404" />
                </Switch>
              </Router>
            </HelmetProvider>
          </StylesProvider>
        </ThemeProvider>
      </Suspense>
    </SfApp>
  );
};

AppBase.propTypes = {
  user: PropTypes.shape({
    eid: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ user }) => ({ user });

export const App = connect(mapStateToProps)(AppBase);
